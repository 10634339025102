import SEO from 'ui/SEO';

const NotFound = () => (
  <>
    <SEO title="Not Found" description="" />
    <div style={{ textAlign: 'center', marginTop: '10px' }}>Not Found</div>
  </>
);

export default NotFound;
